@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'inknut_antiqua';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/Inknut_Antiqua/InknutAntiqua-Regular.ttf) format('truetype');
  }
}

* {
  scrollbar-color: theme('colors.color-2') theme('colors.color-1');
  scrollbar-width: thin;
}

.popup-overlay {
  background-color: #00000099;
}

h1,
h2 {
  color: theme('colors.color-1');
  font-family: 'inknut_antiqua';
}

h3 {
  color: #ffffffff;
  font-family: 'inknut_antiqua';
}

p a {
  color: theme('colors.color-5');
}
